
import { Component, Vue } from "vue-property-decorator";

import ExcelImportExamReport from "@/components/Excel/RE/ExcelImportExamReport.vue";

@Component({
  components: {
    ExcelImportExamReport,
  },
})
export default class ExcelImportRe extends Vue {}
