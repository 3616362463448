
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import {
  ImportWarning,
  ImportWarningType,
  IMPORT_WARNING_TYPES_DISPLAY,
} from "@/domain";

import { HistoricType, HISTORIC_TYPES_DISPLAY } from "prometheus-synced-ui";

@Component({
  data: () => {
    return {
      ImportWarningType,
      HistoricType,
    };
  },
})
export default class WarningDisplay extends Vue {
  @Prop({ required: true })
  value!: ImportWarning;

  get warningTypeDisplay() {
    return IMPORT_WARNING_TYPES_DISPLAY.get(this.value.type);
  }

  get historicTypeDisplay() {
    return HISTORIC_TYPES_DISPLAY.get(this.value.historicType);
  }
}
