
import { Component, Inject, Ref, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import WarningDisplay from "@/components/Excel/RE/ImportResult/WarningDisplay.vue";

import { isXlsx } from "@/tools";
import { IExcelExamReportService } from "@/services";

import { ImportWarning } from "@/domain";

@Component({
  components: {
    WarningDisplay,
  },
})
export default class ExcelImportExamReport extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Ref("form")
  form!: any;

  get excelExamReportService() {
    return this.container.resolve<IExcelExamReportService>(
      S.EXCEL_EXAM_REPORT_SERVICE
    );
  }

  file: File | null = null;
  loading: boolean = false;
  valid: boolean = true;
  success: boolean = false;
  warnings: ImportWarning[] = [];

  isAllowed() {
    return isXlsx(this.file);
  }

  async importFile() {
    try {
      this.loading = true;
      this.success = false;
      if (this.form.validate()) {
        this.warnings = await this.excelExamReportService.import(this.file!);
        this.success = true;
      }
    } finally {
      this.loading = false;
    }
  }
}
